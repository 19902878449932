.d-grid {
    border-color: #4C4B16;
    border-radius: 10px;
}

.newProjectForm {
    padding: 40px;
    border-radius: 10px;
    color: black;
    background-color: rgb(243, 222, 186);
    margin: 10px 245px;
}

.btn-success {
    background-color: #7C9D96 !important;
    border-color: #4C4B16 !important;
}

@media (max-width: 768px) {
    .newProjectForm {
        margin: 10px auto;
        max-width: 90%; 
    }
}