.navbar {
    background-color: #475C5A;
    font-size: 21px !important;
    font-family: 'Montserrat', sans-serif;
    margin-bottom: 50px;
}

.navbar .nav-link {
    margin: 0 10px;
}

.nav-link {
    color: #EEE0C9 !important;
}

.nav-link:hover {
    color: whitesmoke !important;
}
