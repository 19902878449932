@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200&family=Wix+Madefor+Display:wght@800&display=swap');


body {
  background-color: #EAC696;
} 

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

h2 {
  font-family: 'Wix Madefor Display', sans-serif;
}

html, body {
  margin: 0;
  padding: 0;
} 

p {
  font-family: 'Montserrat', sans-serif;
}

#root {
  font-family: 'Montserrat', sans-serif;
  min-height: 100vh;
}
