.wrapper {
    height: 100vh;
}

.signUp {
    width: 300;
    padding: 20px;
    height: min-content;
    border-radius: 10px;
    color: black;
    background-color: rgb(243, 222, 186);
}

.btn-success {
    background-color: #7C9D96 !important;
    border-color: #4C4B16 !important;
}