@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500;700&family=Wix+Madefor+Display:wght@800&display=swap');

h3 {
    font-family: 'Wix Madefor Display', sans-serif;
}

.app-description {
    color: black;
    font-size: 60px;
}

.bold-text {
    font-size: 25px;
    font-weight: 800;
}

.card-image {
    max-height: 170px; 
    object-fit: cover; 
}

.home-section .custom-mb {
    margin-bottom: 100px; 
}

.custom-mt {
    margin-top: 45px;
}

.feature-heading {
    font-size: 40px;
}

.feature-text {
    font-size: 20px;
}

.greeting {
    font-size: 35px;
}

.image-container {
    border-radius: 20px; 
    height: auto;
    max-width: 100%;
}

.metal-card {
    margin: 1rem; 
}

.metal-cards-title {
    color: #7A3E3E;
    font-size: 35px;
}

.regular-text {
    font-family: 'Montserrat', sans-serif;
    font-size: 25px;
    font-weight: 500;
}
